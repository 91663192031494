section[data-section='ranger-black'] {
  position: relative;

  .boton-consulta {
    position: absolute;
    bottom: 3.6%;
    left: 50%;
    transform: translateX(-50%);
    content: '';
    background: transparent;
    color: transparent;
    box-shadow: none;
    //border:1px solid red;
    padding: 10px 50px;
  }
}

#detalle {

  .menu-detail {
    margin-top: 10px;
    //border:1px solid red;
    text-align: center;

    .btn-primary {
      background: transparent;
      border: 1px solid $azul;
      color: $azul;
      text-shadow: none;
      border-radius: 0;
      margin: 8px 5px;
      font-size: 1.1rem;
      font-weight: 600;
      text-transform: uppercase;
      transition: all .3s ease;
      box-shadow: none;

      &:hover {
        background: $azul;
        color: white;
      }
    }
  }

  .cover {
    position: relative;

    #slide-detail {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      z-index: 888;
      background: rgba(black, .1);
      padding: 40px 20px 0 50px;

      @media (max-width: $break-mobile) {
        text-align: center;
        left: 0;
        top: 0;
        position: relative;
        background: $azul-gusp;
        padding: 10px 5px;
      }

      .btn {
        font-size: 1.2rem;
        border: none;
        box-shadow: none;

        padding: .7rem 2.6rem;
        text-transform: uppercase;
        background: $azul-gusp;
        transition: all .3s ease;

        &:hover {
          background: white;
          color: $azul-gusp;
        }

        @include mobile {
          display: block;
        }
      }

      h1 {
        margin: 0;
        text-transform: uppercase;
        color: white;
        font-size: 4.5rem;
        text-shadow: 2px 2px 0 black;
      }
    }
  }


  .galeria-detail {
    position: relative;

    #galeria {
      .item-galeria {
        padding: 0;
        overflow: hidden;
        height: 258px;
        display: block;

        a {
          display: block;
          height: 100%;
          width: 100%;
          background-size: cover;
          background-position: center center;
        }
      }
    }
  }


  h2, h3 {
    text-transform: uppercase;
    font-size: 3.5em;
    font-weight: 200;
    text-align: center;
    color: #a2a2a2;
    margin: 1em 0;
  }

  .modelos-detail {
    position: relative;

    .item-modelo {
      text-align: center;
      margin: 0 auto;

      img {
        max-height: 250px;
        display: block;
        margin: 0 auto 15px auto;

        @include mobile{
          max-height: 190px;
        }
      }

      h4 {
        text-transform: uppercase;
        font-size: 1.4em;
        color: $azul-gusp;
      }
    }

    .slick-dots {
      bottom: -12% !important;

      @include mobile{
        display: none !important;
        bottom: -20% !important;
      }

      button:before {
        color: $azul !important;
        font-size: 9px !important;
        opacity: 1 !important;
      }

      li button {
        //padding: 3px !important;
      }

      li.slick-active {

        button:before {
          transition: all .3s ease;
          color: transparent !important;
          font-size: 12px !important;
          background: transparent;
          border-radius: 50%;
          border: 2px solid $azul;
          box-shadow: none !important;
          text-shadow: none;
        }
      }
    }
  }


  .color {
    border-radius: 3em !important;
    padding: 1em 1.3em;
    margin: 0 .3em;
    text-shadow: none;
    height: 55px;
    width: 55px;
    border: 2px solid white;
    box-shadow: 1px 1px 1px grey;
    transition: all .3s;
    position: relative;

    &.active {
      &:before {
        content: '\f00c';
        font-family: 'FontAwesome', sans-serif;
        font-size: 1.7em;
        line-height: 55px;
        color: white;
        text-shadow: 0 0 1px black;
      }
    }

    @media (min-width: 800px) {
      &:hover {
        box-shadow: 1px 1px 4px grey;
        //border-color: red;
        transform: scale(1.1);
      }
    }

    &:before {
      border-radius: 3em;
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      z-index: 600;
      background: linear-gradient(90deg, rgba(69, 69, 69, 0) 50%, rgba(7, 7, 7, 0.05) 50%);
    }
  }

  .color-container {
    text-align: center !important;
    width: auto;
    display: block;
    margin: 0 auto;
  }

  div.load.slick-initialized.slick-slider > button {
    &.slick-next.slick-arrow {
      right: 0;

      &:before {
        color: grey !important;
      }
    }

    &.slick-prev.slick-arrow {
      left: 0;

      &:before {
        color: grey !important;
      }
    }
  }

  .slick > button {
    &.slick-prev.slick-arrow {
      left: 0;

      &:before {
        color: grey !important;
      }
    }

    &.slick-next.slick-arrow {
      right: 0;

      &:before {
        color: grey !important;
      }
    }
  }
}

#solicitar-test {

  label, input {
    display: inline-block !important;
  }

  input, select {
    margin-top: 2px;
  }

  label {
    letter-spacing: 1px;
    font-size: 11px;
    padding-bottom: 0;
    margin-bottom: 0;
    text-transform: uppercase;
  }
}