section.previa-eventos {
  background-color: #eaeff6;

  .slide-button {
    img {
      width: 100%;
      margin: 0 auto;
      display: block;
    }
  }

  .info-cyber {
    padding: 50px 0;
    //border:1px solid red;
    display: flex;
    align-items: center;

    h1 {
      color: $naranja_hotsale;
      font-family: $FordAntenaMedium;
    }

    h3 {
      font-family: $FordAntenaLight;
      margin: 10px 0;
    }


    .form-previa {
      padding: 15px 30px;
      border-radius: .3em;
      background: $naranja_hotsale;
      margin-top: 35px;

      //background : -moz-linear-gradient(0% 50% 0deg,rgba(110, 157, 242, 1) 0%,rgba(104, 161, 240, 1) 4.11%,rgba(93, 168, 237, 1) 15.71%,rgba(89, 170, 236, 1) 28.09%,rgba(111, 147, 223, 1) 66.85%,rgba(118, 142, 216, 1) 88.76%,rgba(109, 114, 185, 1) 100%);
      background: -webkit-linear-gradient(0deg, rgba(110, 157, 242, 1) 0%, rgba(104, 161, 240, 1) 4.11%, rgba(93, 168, 237, 1) 15.71%, rgba(89, 170, 236, 1) 28.09%, rgba(111, 147, 223, 1) 66.85%, rgba(118, 142, 216, 1) 88.76%, rgba(109, 114, 185, 1) 100%);
      background: -webkit-gradient(linear, 0% 50%, 100% 50%, color-stop(0, rgba(110, 157, 242, 1)), color-stop(0.0411, rgba(104, 161, 240, 1)), color-stop(0.1571, rgba(93, 168, 237, 1)), color-stop(0.2809, rgba(89, 170, 236, 1)), color-stop(0.6685, rgba(111, 147, 223, 1)), color-stop(0.8876, rgba(118, 142, 216, 1)), color-stop(1, rgba(109, 114, 185, 1)));
      background: -o-linear-gradient(0deg, rgba(110, 157, 242, 1) 0%, rgba(104, 161, 240, 1) 4.11%, rgba(93, 168, 237, 1) 15.71%, rgba(89, 170, 236, 1) 28.09%, rgba(111, 147, 223, 1) 66.85%, rgba(118, 142, 216, 1) 88.76%, rgba(109, 114, 185, 1) 100%);
      background: -ms-linear-gradient(0deg, rgba(110, 157, 242, 1) 0%, rgba(104, 161, 240, 1) 4.11%, rgba(93, 168, 237, 1) 15.71%, rgba(89, 170, 236, 1) 28.09%, rgba(111, 147, 223, 1) 66.85%, rgba(118, 142, 216, 1) 88.76%, rgba(109, 114, 185, 1) 100%);
      -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#6E9DF2', endColorstr='#6D72B9' ,GradientType=0)";
      background: linear-gradient(90deg, rgba(110, 157, 242, 1) 0%, rgba(104, 161, 240, 1) 4.11%, rgba(93, 168, 237, 1) 15.71%, rgba(89, 170, 236, 1) 28.09%, rgba(111, 147, 223, 1) 66.85%, rgba(118, 142, 216, 1) 88.76%, rgba(109, 114, 185, 1) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#6E9DF2', endColorstr='#6D72B9', GradientType=1);

      > .row {
        display: flex;
        align-items: center;

        @include mobile {
          flex-direction: column;
        }

        > div {
          flex: 1;
          padding: 0 5px;
        }
      }

      .form-control {
        box-shadow: none;
        margin: 0;
      }

      button {
        background-color: $amarillo_black !important;
        color: $naranja_hotsale;
        border: none;
        border-radius: 0;
        font-family: $FordAntenaMedium;
        height: 34px;
        margin: 0;
        font-weight: 600;
        background: $azul;
        transition: all .3s;

        &:hover {
          background: white;
          color: $azul;
          box-shadow: 1px 1px 1px $azul;
        }
      }

      h2 {
        text-align: center;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 2.3rem;
        margin-top: 0;
      }
    }

  }

  .centro-hot {
    padding: 50px 0;
    background-position: center center;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    //background-color: #3E9DEE;
    background-color: $naranja_hotsale;

    @include mobile {
      height: auto;
      padding: 10px 0;
      background-size: auto 100%;
      background-repeat: no-repeat;
    }

    .flip {
      text-align: center;
      padding: 0;

      @include mobile {
        //transform: scale(.8);
        transform: translateY(10px) scale(.8);
      }

      .data {
        width: 463px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;

        span {
          flex: 1 1;
          text-align: center;
        }

      }

      h3 {
        font-size: 3rem;
        //color:white;
        margin: 0 0;
        text-transform: uppercase;
        color: white;
        font-family: $FordAntenaLight;
        letter-spacing: 2px;
        @include mobile {
          display: none;
        }
      }

      #flipcountdownbox6 {
        margin: 0 auto;
        width: auto;
        display: inline-block;
        text-align: center;
        padding: 10px 0;
        @include mobile {
          padding-top: 0;
        }
      }
    }

    .texts {
      padding: 15px 0 0 0;

      p {
        display: block;
        color: white;
        text-align: center;
        font-family: $FordAntenaLight;
      }
    }
  }

  .items-cyber {
    //background : -moz-linear-gradient(0% 50% 0deg,rgba(208, 232, 251, 1) 0%,rgba(207, 215, 237, 1) 100%);
    background: -webkit-linear-gradient(0deg, rgba(208, 232, 251, 1) 0%, rgba(207, 215, 237, 1) 100%);
    background: -webkit-gradient(linear, 0% 50%, 100% 50%, color-stop(0, rgba(208, 232, 251, 1)), color-stop(1, rgba(207, 215, 237, 1)));
    background: -o-linear-gradient(0deg, rgba(208, 232, 251, 1) 0%, rgba(207, 215, 237, 1) 100%);
    background: -ms-linear-gradient(0deg, rgba(208, 232, 251, 1) 0%, rgba(207, 215, 237, 1) 100%);
    -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#D0E8FB', endColorstr='#CFD7ED' ,GradientType=0)";
    background: linear-gradient(90deg, rgba(208, 232, 251, 1) 0%, rgba(207, 215, 237, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#D0E8FB', endColorstr='#CFD7ED', GradientType=1);

    padding: 70px 0;

    @include mobile {
      img {
        margin-bottom: 10px;
      }
    }

    .item-c {
      background: white;
      padding: 20px;
      text-align: left;

      figure {
        text-align: center;

        img {
          max-height: 180px;
        }
      }

      h3, h4 {
        text-transform: uppercase;
      }

      h3 {
        font-weight: 900;
        font-size: 28px;
        font-family: "FordAntenna-Black", sans-serif;
        color: $naranja_hotsale;
      }

      h4 {
        margin-top: 5px;
        color: #808285;
        font-weight: 900;
        font-family: FordAntenna-Medium, sans-serif;
      }
    }
  }

  .pasos {
    padding: 60px 0 80px 0;
    background-color: white;


    .container {
      > div {
        text-align: center;

        figure {
          height: 130px;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            max-height: 100px;
          }
        }

        h3 {
          color: $naranja_hotsale;
          margin-top: 10px;
          margin-bottom: 15px;
          font-size: 18px;
          font-weight: 800;
        }

        p, h3 {
          font-family: $FordAntenaLight;
          display: block;
          text-align: center;
        }

        p {
          font-size: 14px;
        }
      }
    }
  }
}

@include mobile {
  .xdsoft_flipcountdown {
    .xdsoft_digit {
      width: 50px;
    }

    .xdsoft_space {
      width: 7px !important;
    }
  }
}