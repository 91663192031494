section.plan-ovalo-section {

  h2, h1 {
    color: $azul;
  }

  .nav-planes {
    //margin-bottom: 10px;
    display: block;
    width: 80%;
    margin: 0 auto;

    > ul {
      display: flex;
      text-align: center;
      align-items: center;
      justify-content: center;

      @include mobile {
        flex-direction: column;
      }


      li {
        flex: 1;

        a {
          font-family: $FordAntenaMedium;
          padding: 10px;
          display: block;
        }
      }
    }
  }


  .descubri {

    h2 {
      font-family: $FordAntenaMedium;
      font-size: 2vw;
    }

    h3, h4 {
      font-family: $FordAntenaMedium;
    }

    .descubri-plan {
      padding: 55px 0;
      display: block;
      text-align: center;

      .video {
        margin-top: 20px;

        iframe {
          width: 80%;
          height: 850px;
        }
      }

      h4 {
        margin-top: 5px;
      }
    }


    .comprar {
      background-color: lightgrey;
      display: flex;
      align-items: center;
      justify-content: space-between;

      @include mobile {
        flex-direction: column;
      }

      .text {
        flex-basis: 55%;
        @include mobile {
          flex-basis: 100%;
        }

        .como-comprar {
          padding: 0 0 0 40px;

          @include mobile {
            padding: 10px;
          }

          h2 {
            color: $azul-gusp;
            margin-bottom: 0;
            font-family: $FordAntenaMedium;
            font-weight: 600;
            font-size: 3.5rem;
            //text-transform: uppercase;
          }

          .pregs {
            padding: 10px;

            p {
              font-family: $FordAntenaLight;
            }

            h3 {
              font-weight: 800;
              font-family: $FordAntenaBlack;
              text-transform: uppercase;
              font-size: 1.6rem;
            }
          }
        }

      }

      .image {
        flex-basis: 40%;
        height: 650px;
        background-size: cover;
        background-position: center center;
        //border:1px solid red;

        @include mobile {
          flex: unset;
          display: block;
          height: 650px !important;
          width: 100%;
        }
      }
    }

  }

  .preguntas {
    padding: 55px 0;

    h2 {
      text-align: center;
      display: block;
      margin: 20px auto;
      text-transform: uppercase;
    }

    .item-pregunta {
      margin-bottom: 15px;
      //border: 1px solid red;

      .pregunta {
        color: $azul-gusp;
        font-weight: 700;
        margin-bottom: 10px;
      }

      .respuesta {
        font-family: $FordAntenaLight !important;

        p, span, ul, li {
          font-family: $FordAntenaLight !important;
        }
      }
    }
  }

  .replica, .sobre-planes, .estado-cuota, .info-licitar {
    padding: 50px 0;

    h2 {
      text-transform: uppercase;
      font-family: $FordAntenaMedium;
      display: block;
      margin: 0 auto;
      text-align: center;
      padding-bottom: 10px;
    }

    p {
      text-align: center;
    }

    form {
      width: 80%;
      display: block;
      margin: 0 auto;
    }
  }

  .sobre-planes {
    width: 80%;
    margin: 0 auto;

    h4 {
      font-family: $FordAntenaBlack;
      color: $azul-gusp;
    }

    p {
      text-align: left;
    }
  }

  h1 {
    margin-top: 0;
  }

  .item {
    display: flex;
    border: 1px solid lightgray;
    margin-bottom: 15px;
    margin-top: 15px;
    align-items: center;

    @include mobile {
      flex-direction: column;
    }

    .image {
      text-align: center;
      background: white;
      flex-basis: 30%;

      @include mobile {
        flex-basis: 100%;
        padding: 10px 0;
      }

      img {
        display: block;
        margin: 0 auto;
      }
    }

    .info {
      flex: 1;
      background: #dddddd;
      padding: 1% 2%;

      @include mobile {
        padding: 5%;
      }

      .data-title {
        padding-bottom: 10px;

        .name {
          font-family: $FordAntenaSemiBold;
          @include mobile {
            font-size: 1.6em;
            text-align: center;
          }
        }

        .tipo {
          @include mobile {
            text-align: center;
          }
        }
      }

      .beneficios {
        text-transform: uppercase;
        color: $azul-gusp;
        font-family: $FordAntenaSemiBold;
        margin-top: 15px;
      }

      .old-price {
        color: #707070;
        font-size: 2em;
      }

      h4 {
        font-weight: 600;
        text-transform: uppercase;
      }

      .desde, .primer {
        color: grey;
        font-size: 1em;
        text-transform: uppercase;
      }

      .desde {
        margin-bottom: 5px;
      }

      .primer {
        margin-top: 5px;
      }

      .precio {
        font-family: $FordAntenaBlack;
        font-weight: 600;
        font-size: 4.2em;
        color: $azul-gusp;

      }

      .actions {

        a {
          transition: all .3s;
          border: 1px solid #dedede;
          color: $azul;
          font-weight: 600;
          background: white;
          padding: 10px;
          min-width: 100px;

          @media (max-width: $break-mobile) {
            width: 100%;
            display: block;
            padding-top: 10px;
            padding-bottom: 10px;
            font-size: 1.5rem;
          }

          &:hover {
            background: $azul;
            color: white;
            text-decoration: none;
          }
        }
      }
    }
  }

  #consulta-modal-plan {
    .inputs {
      display: flex;

      label {
        flex-basis: 10%;
        min-width: 10%;
        max-width: 10%;
        font-size: 1.2rem;
        padding-right: 15px;
      }

    }
  }

  .logo-top {
    margin-bottom: 14px;
    text-align: center;
    padding-left: 0;

    img {

      max-width: 310px;
      //margin-left: 190px;
      //margin:0 auto;
      //display: block;
    }
  }

  .data-planes {
    background: $azul-gusp;
    padding: 30px;

    ul {
      padding: 0;

      li {
        color: white;
        font-size: 1.3em;
        letter-spacing: 1px;
        text-align: center;
        display: block;
        font-family: $FordAntenaSemiBold;
        list-style: disc;
        padding: 5px 0;

        @include mobile {
          font-size: 1em;
          letter-spacing: unset;

          img {
            height: 20px;
          }
        }
      }
    }
  }
}

.sidebar-plan-ovalo {
  ul {
    li {
      a {
        font-size: 13px !important;
      }
    }
  }
}

#frecuentes {
  ul {
    li a {
      color: black;
      font-weight: 500;
    }
  }
}


#planes_faq {
  margin-top: 10px;

  .panel-heading {
    background: white;

    h4 {
      display: block;
      letter-spacing: 1px;
      font-family: $FordAntenaMedium, sans-serif;

      a {
        color: black;
        display: block;
      }
    }
  }
}

//section[data-section="planes"] {
//  .sobre-planes, .como-comprar {
//    h2, h3 {
//      font-family: $FordAntenaRegular, sans-serif;
//      margin-top: 18px;
//      margin-bottom: 2px;
//      font-size: 1.9rem;
//    }
//
//    p {
//      margin-bottom: 5px;
//      font-size: 1.6rem;
//    }
//  }
//
//}