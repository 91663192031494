.usado-detail {




  .data {
    h2, h3, h4 {
      color: $azul-gusp;
      font-weight: 800;
      font-family: $FordAntenaMedium
    }

    ul {
      display: flex;
      padding-top: 5px;

      li {
        font-size: 1.65rem;
        padding-right: 10px;
        text-align: center;
        font-weight: 400;
      }
    }
  }

  .btn {
    font-family: $FordAntenaLight;
    border: 1px solid $azul-gusp;
    background: transparent !important;
    color: $azul-gusp;
    border-radius: 0;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 1.3rem;

    &:hover {
      background: $azul-gusp !important;
      color: white !important;
    }
  }

  .accesorios {
    position: fixed;
    left: 50%;
    height: auto;
    max-height: 80%;
    overflow-x: auto;
    top: -550%;
    width: 80vw;
    transform: translate(-50%, 0);
    background: white;
    padding: 20px;
    z-index: 999;
    box-shadow: 0 0 5px grey;
    transition: all .6s ease-in-out;


    &.active {
      top: 50%;
      transform: translate(-50%, -50%);
    }

    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      li {
        font-weight: 700;
        font-family: $FordAntenaLight;
        border-bottom: 1px solid lightblue;
        flex-basis: 48%;
        text-align: center;
        padding: 5px;
        font-size: 1.2rem;
      }
    }

    // Visor accesorios mobile
    @include mobile {
      position: relative;
      left: unset;
      top: unset;
      transform: unset;
      width: unset;
      z-index: unset;

      &.active {
        top: 0;
      }

      ul {
        li {
          flex-basis: 100%;
        }
      }
    }
  }

  .back-accesorios {
    background: rgba(black, .5);
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 888;
    display: none;
  }
}