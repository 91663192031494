.solicite {
  text-align: center;
  display: block;
  margin-bottom: 15px;
  margin-top: 10px;

  .btn {
    background: transparent;
    color: $azul;
    border-color: $azul;
  }

  form {
    text-align: left;
  }
}

section[data-section='ranger'] {

  h1.titulo {
    text-transform: uppercase;
    text-align: center;
    color: $azul;
    font-weight: 600;
    margin: 20px 0 0 0;
  }

  .item {
    display: flex;
    box-shadow: none;
    justify-content: center;
    align-items: center;
    height: 50vh;

    @include mobile {
      height: auto;
    }

    > div {
      flex: 1 1;
      padding: 0 40px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      @include mobile {
        width: 100%;
        display: block;

        &.image {
          //border:1px solid red;
          height: 150px;
        }

        &.text-item {
          padding-bottom: 20px;
        }
      }

      h3 {
        text-align: center;
        font-weight: 600;
        display: block;
      }
    }

    &.item-left {
      flex-direction: row;

      @include mobile {
        flex-direction: column;
      }

      > div:first-child {
        background-size: cover;
        background-position: center center;
      }
    }

    &.item-right {
      flex-direction: row-reverse;

      @include mobile {
        flex-direction: column;
      }

      > div:first-child {
        background-size: cover;
        background-position: center center;
      }
    }

  }
}

section[data-section='rangervs'] {

  h1.titulo {
    text-transform: uppercase;
    text-align: center;
    color: $azul;
    font-weight: 500;
    margin: 20px 0;
    span {
      font-weight: 700;
    }
  }

  .item-vs {
    display: flex;
    //border-bottom: 1px solid rgba(grey, .4);
    height: 80vh;
    flex-direction: row;
    @include mobile{
      flex-direction: column;
      height: auto;
      padding: 20px 0;
    }

    > div {
      @include mobile{
        flex: 1 1;
      }
    }

    &:nth-child(3), &:nth-child(5) {
      flex-direction: row-reverse;
      @include mobile{
        flex-direction: column;
      }
    }

    .slider {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      flex-basis: 60%;
      //border:1px solid red;
      .slide{
        width: 100%;
        .item{
          box-shadow: none;
        }
        img{
          box-shadow: none;
        }
      }

      img {
        max-width: 100%;
      }

      .carousel-control {
        background: transparent;
        box-shadow: none;

        span {
          color: rgba(grey, 0.6);
          box-shadow: none;
          text-shadow: none;

          &:before {
            text-shadow: none;
            box-shadow: none;
          }
        }
      }
    }

    .titulo {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 30px;
      h3 {
        margin: 0;
      }
    }

  }
}