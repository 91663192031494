* {
  outline: none !important;
  a:hover {
    text-decoration: none;
  }
  &:focus {
    outline: none !important;
  }
}

.activo {
  background: #0061a3 !important;
  color: black !important;
}

.chat {
  position: fixed;
  right: 3%;
  bottom: 0 !important;
  height: auto;
  background: white;
  width: 250px;
  padding: 0;
  outline: none !important;

  a {
    color: #0061a3;
    outline: none !important;
    &:hover {
      text-decoration: none !important;
      outline: none !important;
    }
    &:focus {
      outline: none !important;
    }
  }
  #chat-container {
    height: auto;
    padding: 0.4em;

    .btn{
      background: transparent !important;
    }

    .btn-link:hover {
      background: #eeeeee;
    }
  }
  #activar-bahia, #activar-rosa, ul li a {
    border-bottom: 1px solid #eeeeee;
  }

  .btn-link {
    color: #0061a3;
    margin: 0;
    display: block;
    text-align: left;
    width: 100%;
    text-transform: uppercase !important;

    &:hover {
      text-decoration: none !important;
    }
  }

  #chat-activate {
    text-align: center !important;
    padding: 0.4em 1em;
    background: black;
    color: white;
    outline: none !important;
  }
}

#rse {
  h1 {
    text-transform: uppercase;
    text-align: center;
    margin: 1em 0;
    font-weight: 100;
  }
  p {
    font-size: 1.3em;
    text-align: justify;
    font-weight: 200;
  }
}

#contactanos {
  width: 50%;
  display: block;
  margin: 0 auto;
  padding: 4em 0;
  h2 {
    text-transform: uppercase;
    text-align: center;
    padding: 2rem;
  }
  a {
    text-transform: uppercase;
    text-align: center;
    display: block;
    color: #0a4c92;
    border: 2px solid #0a4c92;
    padding: 3em 0;
    width: 50%;
    margin: 0 auto;
    border-radius: 9em;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    &:hover {
      background: #0a4c92;
      color: white;
    }
    i {
      font-size: 4em;
    }
  }
}
