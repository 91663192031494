section[data-section="landing-instagram"] {
  background-color: $azul-ford-2;

  > .container {
    //background-image: url("../img/landing-instagram.jpg");
    //background-position: top center;
    //background-color: red;
    background-color: white;
    padding-top: 20px;
    padding-bottom: 20px;
    width: 90%;
    transform: translateY(-25px);

    > h2 {
      font-family: $FordAntenaMedium;
      color: $azul-gusp;
      text-align: center;
      display: block;
      font-size: 1.6em;
      margin-bottom: 10px;
    }

    > h3 {
      font-family: $FordAntenaMedium;
      color: grey;
      text-align: center;
      display: block;
      margin-bottom: 15px;
      font-size: 1.3em;

      span {
        font-family: $FordAntenaBlack;
        display: block;
        padding-bottom: 5px;
      }
    }


    form {
      label {
        text-align: center;
        display: block;
        font-family: $FordAntenaMedium;
        color: $azul-gusp;
      }
    }


  }
}