.sidebar {
  h3, h4 {
    text-align: center;
    display: block;
    font-size: 1.5em;
    line-height: 1.4em;
    text-transform: uppercase;
  }
  a {
    border-radius: 0 !important;
    border-bottom: 1px solid #dedede;
  }
  .well {
    background: #f8f8f8;
    border: none;
    margin: 1em 0;
    padding: 1.5em;
    box-shadow: 1px 1px 2px rgba(128, 128, 128, 0.52);

    a {
      text-transform: uppercase;
      font-weight: 400;
      padding: .6em 0 .6em .3em;
      color: black;

      &.btn-primary{
        color:white;
      }
    }
  }
}