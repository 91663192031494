.replica_cuota {
  border: 2px solid $azul-gusp;
  .info-g{
    padding: 5%;
    h4{
      text-align: center;
      text-transform: uppercase;
    }
    p{
      font-size: 14px;
      line-height: 1.4;
    }
  }
  ul {
    li {
      margin-bottom: 10px;

      b {
        text-transform: uppercase;
        font-weight: 800;
      }
    }
  }

  .adherente {
    text-transform: uppercase;
    font-weight: 800;
    color: $azul-gusp;
  }

  .line {
    display: block;
    padding: 5px;
    background-color: $azul-gusp !important;
    width: 100%;

    h3 {
      text-transform: uppercase;
      color: white !important;
      font-weight: 800;
      margin-left: 15px;
    }
  }

  section {
    position: relative;
    padding: 20px;

    .bottom-text {
      position: absolute;
      bottom: 0.5%;
      text-align: center;
      font-size: 10px;
      font-weight: 400;
      color: $azul-gusp !important;
      width: 100%;
      left: 0;
      text-transform: uppercase !important;
    }
  }

  header {
    display: block;
    width: 100%;

    > div {
      padding: 10px;
      height: 120px;
      margin-bottom: 15px;
    }

    h2 {
      color: $azul-gusp !important;
      font-weight: 800;
    }

    h3 {
      text-transform: uppercase;
      text-align: center;
      color: $azul-gusp !important;
    }

    img {
      max-height: 90px;
      display: block;
      margin: 0 auto;
    }
  }
}