section[data-section="revendedores"] {

  .botones {
    .btn {
      display: block;
    }
  }

  .btn-outline {
    background: transparent !important;
    color: $azul;
    font-size: 1.3rem;
    font-weight: 600;
  }

}