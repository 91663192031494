footer {
  width: 100%;
  padding: 4rem 0;
  background-color: $dark-grey;

  @include mobile {
    text-align: center;
  }
  .container{
    position: relative;

    .logo-footer-contain {
      position: absolute;
      //border:1px solid red;
      display: flex;
      align-items: center;
      justify-content: space-around;
      height: 118px;
      right: 0;
      top:10px;

      @include mobile{
        bottom: 20px;
        top:unset;
      }

      .afip {
        //flex-basis: 15%;
        //border:1px solid yellow;
        img {
          width: auto;
          height: 70px;
        }
      }

      .logo-footer {
        flex-basis: 70%;
        display: flex;
        width: 90%;
        margin: 0 auto;

        > div {
          display: flex;
          //border: 1px solid green;
        }

        @include mobile {
          padding: 0 5%;
        }

        > div {
          padding: 10px;

          &:first-child {
            svg {
              max-height: 40px;

              g, path {
                fill: white;
              }
            }
          }

          &:nth-child(2) {
            svg {
              max-height: 40px;
              //transform: translateY(-11px);
            }
          }

          svg {
            width: 100%;

            #texto {
              g, path {
                fill: white;
              }
            }
          }
        }
      }
    }

  }

  .row {
    display: flex;

    @include mobile {
      flex-direction: column;
    }

    > div {
      flex: 1;
      padding-left: 0;
      //border:1px solid red;

      li{
        a{
          font-size: 12px;
          font-family: $FordAntenaExtraLight;
        }
      }
    }
  }

  h3 {
    margin-bottom: 0.5rem;
    position: relative;
    font-size: 15px;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: white;
    font-weight: 500;
    font-family: $FordAntenaMedium;

    @include mobile {
      margin-top: 20px;
    }
  }

  button.btn {
    background: transparent;
    border: 1px solid white;
    margin-top: 1.5rem;
  }

  input[type='text'] {
    background: transparent;
    border: none;
    border-bottom: 1px solid white !important;
    box-shadow: none;
    outline: none;
    color: white;

    &::placeholder {
      color: white;
    }

    &:focus, &:active {
      outline: none;
      box-shadow: none;
    }
  }

  a, button, .btn {
    color: #FFFFFF !important;
  }

  p {
    font-size: 12px;
  }

  #contactenos {

    .socials {
      text-align: center;

      @media (max-width: 560px) {
        padding-bottom: 1em;
      }

    }

    @media (max-width: $break-mobile) {
      .afip {
        text-align: center;
        display: flex;
        justify-content: center;

        .img-afip {
          display: block;
          margin-top: 10px;
          max-height: 100px;
        }
      }
    }

  }
}

#sucs-what {

  h3, h4, label {
    color: black;
  }

  button.btn {
    background-color: $azul-gusp;
    color: white !important;
    text-shadow: none;

    &:hover {
      background: black;
      color: white;
      text-decoration: none;
    }
  }
}