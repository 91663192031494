.logo-mobile-top {
  text-align: center;
  padding-top: 10px;
  //display: flex;
  justify-content: center;
  align-items: center;

  @include mobile{
    display: flex !important;
  }

  .logo-g {
    padding: 15px 0 0 0;
    display: block;
    max-width: 150px;

    svg {
      width: 100%;
      max-height: 25px;

      #texto {
        g, path {
          fill: $azul;
        }
      }
    }
  }
}

.nav-mobile-container {

  a {
    display: none;

    @include mobile {
      display: block;
      color: white;
      padding: 15px 0 1px 0;
      text-align: center;
      text-transform: uppercase;
      font-size: 1.3rem;

      &.show-mobile {
        color: $dark-grey;
      }
    }
  }

}

.nav-mobile {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: -150%;
  left: 0;
  transition: all .3s;
  background: $azul-gusp;
  padding: 30px;
  display: none;
  z-index: 999;

  @media (max-width: $break-mobile) {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &.active {
    opacity: 1;
    top: 0;
  }

  figure {
    line-height: 0;
    margin-bottom: 0;

    .logo-mobile {
      text-align: center;

      svg {
        max-width: 80%;
        margin: 0 auto;
        display: block;

        g, path {
          fill: white;
        }
      }
    }

    img {
      max-width: 100%;
    }
  }

  ul {
    display: flex;
    flex-direction: column;
    //border: 1px solid red;

    li {
      display: block;
      padding: 8px;
      text-align: center;
      font-size: 1.4rem;
      line-height: 1.2;
      letter-spacing: 1px;
      font-weight: 300;
      border-bottom: 1px solid darken($azul-gusp, 1.2);

      a {
        color: white;
        text-transform: uppercase;
        text-align: center;
        padding: 12px 0;
      }
    }
  }
}
