header {
  background: white;
  position: relative;
  z-index: 900;
  padding-top: 0;
  box-shadow: 0 0 2px grey;
  transition: all .3s ease;
  height: 90px;

  &.header-sticky {
    position: fixed;
    left: 0;
    top: 0;
    height: 65px;
    width: 100%;
    @extend .fadeInDown;

    .nav-superior {
      display: none;
    }

    .logo-web {
      transform: translateY(3px);

      .logo-40 {
        max-height: 65px;
      }
    }
  }

  nav.nav-superior {
    width: 100%;
    background: $gris;
    padding: 3px 0;

    .container, .container-fluid {
      padding: 0;
      display: flex;
      justify-content: flex-end;

      ul {
        display: flex;

        li {
          display: flex;

          a {
            text-transform: uppercase;
            color: #898989;
            padding: 5px 10px;
            width: 100%;
            position: relative;
            height: 100%;
            font-size: 12px;
            font-family: $Roboto;
            font-weight: 400;

            &:hover {
              color: white;
              background: #00528a;
            }
          }
        }
      }
    }
  }

  .container-principal {
    //padding: 10px 0;

    #principal {
      width: 100%;

      ul {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        height: 42px;

        li {
          flex: 1 1;
          text-align: center;

          a {
            color: $azul;
            width: 100%;
            height: 100%;
            font-weight: 500;
            font-size: 1.2rem;
            display: block;
            padding: 6px 0;
            transition: all .3s ease;
            position: relative;
            text-transform: uppercase;

            &:after {
              transition: all .2s ease;
              background: $azul;
              content: '';
              position: absolute;
              left: 0;
              bottom: 0;
              width: 100%;
              height: 2px;
              transform: scaleX(0);
            }

            &:hover {
              &:after {
                opacity: 1;
                transform: scaleX(1);
              }
            }
          }

        }
      }

    }
  }

  .nav-principal {
    height: 63px;
    display: flex;
    justify-content: center;
    align-items: center;

    .container-fluid {
      width: 100%;
    }
  }
}