section[data-section="seguimiento"] {
  .form-seg {
    position: relative;

    form {
      position: absolute;
      background-color: $azul-ford;
      padding: 20px 30px;
      text-align: center;
      width: 25%;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      @include mobile {
        width: 100%;
        left: unset;
        transform: unset;
        position: relative;
      }
    }

    .btn {
      border-radius: 0;
      margin-top: 10px;
      width: 100%;
      text-transform: uppercase;
      font-family: $FordAntenaMedium;
      background-color: $azul-ford-2;
    }

    h3 {
      color: white;
      font-family: $FordAntenaMedium;
      text-transform: uppercase;
      font-size: 1.8rem;
      margin-bottom: 10px;
    }

    .input-group-addon {
      padding-top: 0;
      padding-bottom: 0;

      i {
        line-height: 1;
      }
    }

    .input-group-addon, .form-control {
      height: 40px !important;
      margin: 0;
      border: none;
      border-radius: 0;
    }
  }
}


section[data-section="seguimiento-codigo"] {

  .historial {
    h3 {
      color: $azul-ford;
      text-transform: uppercase;
      font-size: 1.8rem;
      margin-bottom: 5px;
      font-family: $FordAntenaMedium;
      @include mobile {
        margin: 10px 0;
      }
    }

    ul {
      li {
        padding: 10px 0;
        background-color: white;
        border-bottom: 1px solid lightgrey;
        display: block;
      }
    }
  }

  .estados {
    padding: 10px;

    ul {
      border-radius: 5px;
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      border: 1px solid $azul-ford;
      padding: 5px;

      @include mobile {
        flex-direction: column;
      }

      > li {
        background-image: url("../img/seg/arrow-seg.png");
        background-position: right center;
        background-repeat: no-repeat;
        flex: 1;

        @include mobile{
          width: 100%;
          margin-bottom: 5px;
        }

        h3 {
          color: $azul-ford;
          display: block;
          padding: 15px;
          font-size: 1.2rem;
          text-transform: uppercase;
          font-family: $FordAntenaMedium;
          text-align: center;
          letter-spacing: 1px;
        }

        &.active {
          background-image: url("../img/seg/arrow-active.png");

          @include mobile {
            display: block;
          }

          h3 {
            color: black;
          }
        }

      }
    }
  }

  .data-seg {
    h4 {
      text-transform: uppercase;
      font-family: $FordAntenaMedium;
      color: $azul-ford;
      margin-bottom: 5px;
    }

    ul {
      margin-bottom: 10px;
    }
  }

  .accesorios {
    h4 {
      text-transform: uppercase;
    }

    h5 {
      text-transform: uppercase;
      margin: 5px 0;
    }

    .item-accesorio {
      padding: 10px 15px;
      border: 1px solid #eaeaea;
      margin-bottom: 5px;
      border-radius: 5px;
      background-color: white;
      box-shadow: 0 0 2px lightgrey;
    }
  }

  .pre-entrega {
    .row {
      padding-bottom: 25px;
      padding-top: 25px;
      border-bottom: 1px solid $azul-ford;

      h4 {
        color: $azul-ford;
      }
    }

    .checks {
      display: flex;
      align-items: center;

      span {
        color: $azul-ford;
      }

      .fill-empty, .fill-full {
        height: 15px;
        width: 15px;
        border: 1px solid $azul-ford;
        margin-left: 5px;
        margin-right: 5px;
      }

      .fill-empty {
      }

      .fill-full {
        background-color: $azul-ford;
      }

    }
  }
}