@import 'variables';
@import 'bootstrap';
@import 'bootstrap-select';
@import 'bootstrap-theme';
@import 'bootstrap-utilities';

@import "mixins";
@import 'globals';
@import 'font-awesome';
@import 'fonts';
@import 'layaout';
@import 'header';
@import 'chat';
@import 'footer';
@import 'landing';
@import 'contratar';
@import 'responsive';
@import 'sidebar';
@import 'autos';
@import "tables";
@import "animate";
@import "flip_countdown";
@import "ford_pass";

//Secciones
@import "sec_donde_estamos";
@import 'sec_autos';
@import 'sec_usados';
@import 'sec_encuestas';
@import 'sec_usado_detail';
@import 'sec_ranger';
@import 'sec_inicio';
@import 'sec_plan_ovalo';
@import 'sec_repuestos_pedidos';
@import 'sec_grandes_clientes';
@import 'sec_servicio';
@import 'sec_contactenos';
@import 'revendedores';
@import 'detalle_auto';
@import 'nav_mobile';
@import 'cyber_monday';
@import 'sec_ranger_raptor';
@import 'sec_ranger_campaign';
@import 'sec_f_150';
@import 'sec_f_150_raptor';
@import "replica_cuota";
@import "sec_previa_eventos";
@import "sec_prevencion";
@import "sec_shoowrom";
@import "sec_landing_instagram";
@import "sec_seguimiento";
@import "sec_seguimiento_or";


@media (max-width: 480px) {
  .landing .form-datos {
    .btn {
      width: 100%;
    }

    .logo img {
      margin-top: 10%;
    }
  }
}

p {
  text-align: justify;
}



#bloques .bloques {
  background: transparent;
  text-align: center;
  margin-top: -4em;

  img {
    padding: 1em;
    background: black;
  }
}

.slider {
  img {
    width: 100%;
  }

  .carousel-item {
    height: auto;
    overflow: hidden;
  }
}

#abajo-slider {
  padding: 0;
  display: block;
  height: auto;
}

#myTabs li a {
  font-size: 1.1em;
}


.map {
  height: 580px;
  padding: 1.5em;
  border: 7px solid white;
  box-shadow: 0 0 2px grey;
  background: white;
}

.landing-page {
  a {

    &:hover {
      display: block;
      box-shadow: 0 0 10px grey;
      transition: all .3s ease;
    }

    img {
      margin: 15px 0;
      display: block;
    }

  }
}

.item {
  background: white;
  box-shadow: 0 0 1px grey;

  &:hover {
    box-shadow: 0 0 4px grey;
  }
}

.precio {
  text-transform: uppercase;
  font-weight: 900;
  font-size: 2.5em;
}



#gestoria {
  ol,
  ul {
    margin-left: 2.8em;
  }

  p {
    font-size: 1.4em;
  }

  h3,
  h4 {
    margin: 1em 0;
  }
}

.card-columns {
  width: 100%;

  .card {
    height: 560px;
    overflow: hidden;

    img {
      width: 100%;
    }
  }
}

#manuales {
  list-style: none;

  li {
    display: block;
    width: 24%;
    float: left;
    margin: 0 0.2em;
  }
}

table {
  width: 100%;
}

#listado li a {
  text-transform: uppercase;
  font-size: 1.2em;
  font-weight: 700;
}

.dropdown {
  li,
  a {
    width: 100% !important;
  }
}

.back {
  display: none;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.61);
  z-index: 888;
}

@media (max-width: 400px) {
  #bloques .bloques {
    margin-top: 1em;
  }
}

@media (max-width: 800px) {
  #principal a,
  #superior a {
    display: none;
  }
}

@media (min-width: 800px) {
  #nav-responsive {
    display: none;
  }
}


section[data-section='repuestos-list'] {
  p {
    margin-top: 5px;
  }
}

section[data-section='repuestos'] {
  .slide-container-rep {
    padding-top: 10px;
    padding-bottom: 10px;
    position: relative;
  }
}

.jumbotron_propio {
    border-radius:8px;
    margin-bottom:12px;
    padding:10px;
    border-radius:8px;
    background-color: white;
    box-shadow:0 0 3px 1px rgba(black,0.3);
    h2{
        margin:5px 0;
    }
}








