.landing {
  font-family: Roboto, sans-serif !important;
  #centro {
    background: white;
    box-shadow: 0 0 2px grey;
  }
  .img-landing{
    display: block;
    padding-top: 2.9em;
  }
  header {
    padding: 2em 0;
    text-align: center;
    height: auto;
    background: #0061a3;
  }
  .form-datos {
    padding: 2.5em 1em;
    background-repeat: no-repeat;
    background-size: cover;
    label {
      color: black;
      text-transform: uppercase;
      font-weight: 300;
    }
    .btn {
      text-transform: uppercase;
      font-weight: 200;
    }
    h2 {
      color: black;
      font-weight: 300;
      text-transform: uppercase;
      //text-shadow: 0 0 2px black;
    }
    .logo img {
      display: block;
      margin: 0 auto;
      margin-top: 22%;
    }
  }
  footer {
    padding: 1.5em 0;
    background: $azul-gusp;
  }
  #conosca {
    text-align: center;
    padding: 3em 0;
    h2 {
      color: #0061a3;
      font-weight: 100;
    }
  }
  #ecosport {
    background-image: url(../img/landings/LG__eco-sport-fondo.jpg);
  }
  #ranger {
    background-image: url(../img/landings/LG__ranger-fondo.jpg);
  }
  #focus {
    background-image: url(../img/landings/LG__focus-fondo.jpg);
  }
  footer {
    ul {
      padding: 0;
    }
    #logo-footer {
      margin-top: 10%;
    }
  }
}