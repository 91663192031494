.autos {
  padding: .3em;
  position: absolute;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  width: 100%;
  z-index: 999;
  min-height: 200px;
  box-shadow: 0 0 2px grey;
  background: whitesmoke;

  .nav-tabs li a {
    color: $azul;
    box-shadow: none;
    font-weight: 400;
    font-size: 13px;
  }

  .tab-content {
    padding: 1em;
    background: white;
  }

  .item-menu {
    float: left;
    height: auto;
    width: 14.2%;
    padding: .5em;
    text-align: center;
    border: 1px solid white;
    transition: all .3s;

    figure {
      line-height: 0;
      height: 106px;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        height: auto;
        max-width: 99%;
      }
    }

    &:hover {
      border: 1px solid #e3e3e3;
      box-shadow: 0 0 1px #d8d8d8;
    }

    h3, a {
      color: $azul;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 12px;
    }

  }
}

.colores {
  padding: 2em;
  display: flex;
  justify-content: center;
  justify-items: center;
  flex-wrap: wrap;

  > div {
    @media (max-width: $break-mobile) {
      margin-bottom: 10px;
      justify-content: left;
      justify-items: left;
    }
  }

  //colores
  .gris-mercurio {
    background: #5B5E5E;
  }

  .gris-tectonic {
    background: #A5998C;
  }

  .rojo-cafayate {
    background: #70282E;
  }

  .gris-perla {
    background: #534b48;
  }

  .gris-magnetic {
    background: #393A3A;
  }

  .gris-mercurio {
    background: #6D7071;
  }

  .plata-metalizado, .plata-lunar {
    background: #898D91;
  }

  .negro-perlado {
    background: #11181b;
  }

  .blanco-oxford {
    background: white;
  }

  .plata-estelar {
    background: #c5c5c5;
  }

  .negro-ebony {
    background: black;
  }

  .azul-mediterraneo, .azul-mediterran, .azul-aurora {
    background: #144F8F;
  }

  .rojo-merlot, .rojo-rubi {
    background: #7B2E36;
  }

  .rojo-bari {
    background: #AC1921;
  }

  .rojo-sport {
    background: #CA2630;
  }

  .rojo-metalico {
    background: #6e1418;
  }

  .cafayate {
    background: #8C2026;
  }

  .azul-baltico {
    background: #2b354d;
  }

  .azul-belice {
    background: #0B2C75;
  }

  .marron-brisbane {
    background: #A5A2A6;
  }

  .colorado-red {
    background: #FF3436;
  }

  .cobre-andino {
    background: #D85C18;
  }
}

.colores-modelo {
  .modelos-color {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    li {
      flex-basis: 24.5%;
      @include mobile {
        flex: 1;
      }

      a {
        display: block;
        border: 1px solid $azul-ford;

        font-family: $FordAntenaMedium;
        text-align: center;

        &.active {
          box-shadow: none;
          color: white;
          background-color: $azul-ford;
        }
      }
    }
  }

  .colores-container {

    .title-modelo-color {
      color: black;
      display: block;
      text-align: center;
      margin: 2px auto !important;
    }

    .item-color {
      text-align: center;
      margin: 0 auto;
      text-transform: uppercase;
      max-height: 570px;
      overflow: hidden;

      @include mobile {
        height: auto;
      }

      img {
        display: block;
        margin: 0 auto;
        height: auto;
        max-height: 550px;
      }
    }
  }

}