section[data-section="seguimiento-or"] {
  figure {
    line-height: 0;
  }

  .sinentrada {
    margin-top: 12px;
    background-color: #ffffff;
    width: 292px;
    height: 451px;
  }

  .tareassinabrir {
    margin-top: 12px;
    background-image: url("../img/servicio/semaforo.jpg");
    background-position: 0px 0px;
    width: 292px;
    height: 451px;
  }

  .tareasabiertas {
    margin-top: 12px;
    background-image: url("../img/servicio/semaforo.jpg");
    background-position: -292px 0px;
    width: 292px;
    height: 451px;
  }


  .tareascerradas {
    margin-top: 12px;
    background-image: url("../img/servicio/semaforo.jpg");
    background-position: -584px 0px;
    width: 292px;
    height: 451px;
  }

  .or_cerrada {
    margin-top: 12px;
    background-image: url("../img/servicio/semaforo.jpg");
    background-position: -876px 0px;
    width: 292px;
    height: 451px;
  }

  .semaforo {
    margin-right: 10px;

    @include mobile {
      text-align: center;

      img {
        text-align: center;
        display: block;
        margin: 0 auto;
      }
    }
  }

  .breadcrum_seg {
    padding: 5px;
    border: 1px solid #4eadfe;
    border-radius: 8px;
    display: block;
    height: 60px;
    font-size: 18px;
    font-weight: bold;
    margin: 10px 20px 10px 10px;

    @include mobile {
      height: unset;
    }

    h4 {
      margin: 0;
    }

    > .row {
      padding: 0;
      margin: 0;
      display: flex;
      align-items: center;
      height: 100%;

      @include mobile {
        flex-direction: column;
      }
      //border: 1px solid red;

      .breadcrum_seg_item {
        height: 100%;
        display: flex;
        align-items: center;
        border-radius: 5px;

        @include mobile {
          width: 100%;
          padding: 10px;
        }

        h4 {
          font-size: 15px;
          text-align: center;
          display: block;
          width: 100%;
          font-family: "FordAntenna-Medium", sans-serif;
        }

        &.active {
          color: white;
          background-color: #4eadfe;
        }
      }
    }
  }

  .estados {
    padding: 10px;

    ul {
      border-radius: 5px;
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      border: 1px solid $azul-ford;
      padding: 5px;

      @include mobile {
        flex-direction: column;
      }

      > li {
        background-image: url("../img/seg/arrow-seg.png");
        background-position: right center;
        background-repeat: no-repeat;
        flex: 1;

        @include mobile {
          width: 100%;
          margin-bottom: 5px;
        }

        h3 {
          color: black;
          display: block;
          padding: 15px;
          font-size: 1.4rem;
          text-transform: uppercase;
          font-family: $FordAntenaMedium;
          text-align: center;
          letter-spacing: 1px;
          font-weight: 700;

          span {
            margin-top: 1px;
            font-size: 9px;
            display: block;

          }
        }

        &.active {
          background-image: url("../img/seg/arrow-active.svg");
          background-size: cover;

          @include mobile {
            display: block;
          }

          h3 {
            color: white;
            text-shadow: 0 0 4px black;
          }
        }

      }
    }
  }

  .ppp {
    border: 0;
  }

  .ppp1 {
    border: 0;
    display: inline-block;
    background-color: #4eadfe;
    //height: 36px;
    border-radius: 8px;
    color: #fff;
    padding: 8px 15px;

    font-family: "FordAntenna-Light", sans-serif;
    font-size: 18px;
    text-transform: uppercase;
    letter-spacing: 1px;

    @include mobile {
      text-align: center;
      display: block;
    }
  }

  .seg_or {
    width: 80%;

    @include mobile {
      width: 100%;
    }
  }

  .seg_or1 {
    color: #4eadfe;
    font-weight: normal;
    font-family: "FordAntenna-Medium", sans-serif;
  }

  .seg_or2 {
    font-weight: bold;
    font-family: "FordAntenna-Medium", sans-serif;
  }

  .seg_or3 {
    font-weight: normal;
    font-family: "FordAntenna-Medium", sans-serif;
  }


  .bgddd {
    background-color: #ddd;
  }

  .bgeee {
    background-color: #eee;
  }


  .table_tareas_seg_or {
    width: 80%;


    th {
      color: #4eadfe;
      text-transform: uppercase;
    }

    tr {
      border-bottom: 1px solid #ddd;
    }

    td {
      font-weight: normal;
      line-height: 18px
    }

    @include mobile {
      width: 100%;

    }
  }

  table {
    @include mobile {
      text-align: left;
      td, tr {
        padding: 3px;
        text-align: left;
      }
    }
  }

  .breadcrum_seg1 {
    display: block;
  }

  .cua {
    font-family: "FordAntenna-Medium", sans-serif;
    margin-top: 4px;
    background-color: #4eadfe;
    display: block;
    color: #fff;
    padding-top: 12px;
    padding-left: 12px;
    font-size: 17px;
  }

  .tri {
    float: left;
    margin-top: 4px;
    width: 0;
    height: 0;
    border-left: 40px solid #4eadfe;
    border-top: 25px solid transparent;
    border-bottom: 25px solid transparent;
  }

  .panel-body {
    padding: 10px 0;
  }
}