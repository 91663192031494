section[data-section='prevencion'] {

  .top-entrada {
    position: relative;

    figcaption {
      left: 2%;
      top: 50%;
      transform: translateY(-50%);
      position: absolute;

      h2, h3 {
        @include mobile {
          font-size: .7em;
        }
      }
    }
  }

  h3, h2, h1 {
    font-family: $FordAntenaRegular;
  }

  .data-items {
    background: $azul-prev;

    .col-xs-12, .col-xs-6, .col-sm-6 {
      padding: 0;
    }

    h3, h4 {
      text-transform: uppercase;
      color: white;
      display: block;
      text-align: center;
      font-size: 2.3rem;
      font-family: $FordAntenaRegular;
      letter-spacing: 1px;

      @include mobile {
        font-size: 1.2em;
      }

      span {
        font-family: $FordAntenaBlack;
      }

      &.title-white {
        background: white;
        color: $azul_prev;
        padding: 10px;
        border-radius: 5px;
        width: auto;
        margin: 10px auto 0 auto;
        font-size: 1.3rem;
        letter-spacing: 1px;

        @include mobile {
          font-size: 1.1em;
          width: 80%;
        }
      }
    }

    img {
      max-height: 70px;
    }

    .item-d {
      background: transparent;
      padding: 20px 10px;
      display: flex;
      flex-direction: column;
      height: 190px;
      justify-content: center;
      border-bottom: 1px solid white;
      margin-bottom: 5px;

      &.contacto-planes {
        h3 {
          font-size: 1.7rem;
        }
      }

      figure {
        padding: 15px;
      }
    }
  }


  .title-para {
    color: $azul;
    font-size: 3rem;
    margin: 10px 0;
  }

  .botones {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin-bottom: 20px;

    @include mobile {
      flex-direction: column;
      padding-left: 10px;
      padding-right: 10px;
    }

    a {
      margin: 0 0 10px 0;
      text-transform: uppercase;
      border-radius: 0;
      transition: background-color .3s ease-in;
      padding: 10px;
      font-size: 2.8rem;
      font-family: $FordAntenaSemiBold;
      flex-basis: 32%;
      @include mobile {
        flex-basis: unset;
      }
    }
  }

}