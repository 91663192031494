.donde-estamos {

  .sucursal-item {
    position: relative;
    padding: 1rem;
    margin: 10px 0;
    box-shadow: 0 0 2px grey;

    iframe {
      width: 100% !important;
    }

    .data {
      background: white;
      padding: 1em;
      padding-left: 20px;
      left: 10px;
      top: 10px;
      position: absolute;
      width: 550px;
      box-shadow: 1.5px 1.5px 2px grey;
      font-size: 12px;

      h3, h4 {
        color: $azul-gusp;
        text-transform: uppercase;
        margin: 0;
      }

    }
  }

  table {
    table-layout: fixed
  }
}