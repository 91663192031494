section[data-section="contacto"] {
  .vias {
    padding-left: 15px;

    h2 {
      text-transform: uppercase;
      font-size: 2.1rem;
      color: $azul-gusp;
      font-weight: 900;
      margin-bottom: 15px;
    }

    h3 {
      text-transform: uppercase;
      color: black;
      font-size: 1.8rem;
      display: block;
      text-align: left;
    }

    h4 {
      color: $azul-gusp;
      font-size: 1.3rem;
      text-transform: uppercase;
      margin-bottom: 5px;
      margin-top: 10px;
      font-weight: 800;
    }

    ul {
      li {
        font-size: 1.3rem;
      }
    }
  }
}