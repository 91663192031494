section[data-section="servicio"] {
  .items {

    h3 {
      color: $azul-gusp;
      text-transform: uppercase;
      font-weight: 400;
      letter-spacing: 1px;
      margin-bottom: 3px;
    }

    img {
      display: block;
      margin-bottom: 20px;
    }
  }


  .servicio-content {
    background-color: $azul;

    .row-content {
      padding-top: 50px;

      .top-banner {
        width: 100%;
      }
      .container-logo, .container-title {
        height: 120px;
        display: flex;
        align-items: center;

        @include mobile {
          height: unset;
          text-align: center;
          justify-content: center;
        }
      }

      @include mobile {
        .container-title {
          padding-top: 25px;
        }
      }

      .contact {
        text-align: center;
        padding-bottom: 30px;
        padding-top: 20px;

        .contactanos {
          margin: 0 auto 15px auto;
          border-radius: 8px;
          background: transparent;
          width: 250px;
          text-transform: uppercase;
          font-family: $FordAntenaLight;
          color: white;
          border: 2px solid white;
          box-shadow: none;
          padding: 10px 25px;
          text-shadow: none;
          letter-spacing: 1px;
        }

        h4 {
          color: white;
          font-family: $FordAntenaMedium;
          margin-bottom: 5px;
        }
      }

      h3, h2 {
        color: white;
        font-family: $FordAntenaLight;

        span {
          padding-bottom: 5px;
          font-family: $FordAntenaMedium;
          font-weight: 700;
          display: block;
        }
      }

      .images-descuentos {
        text-align: center;
        padding-top: 40px;

        @include mobile {
          padding-left: 15px;
          padding-right: 15px;
        }

        img {
          margin: 20px auto !important;
          display: block;

          @include mobile {
            margin: 10px auto !important;
          }
        }
      }
    }
  }
}