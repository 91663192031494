html {
  overflow-x: hidden;
}

.mb-1 {

}

.font-guspamar-medium{
  font-family: $FordAntenaMedium;
}

.banner-corona {
  position: fixed;
  left: 50%;
  top: 50%;
  height: auto;
  width: 80%;
  max-width: 80%;
  transform: translate(-50%, -50%);
  z-index: 1999;
  text-align: center;
  //background: white;

  padding: 10px;

  iframe {
    @include mobile {
      height: 200px;
      max-width: 80%;
    }
  }

  @include mobile {
    width: 90%;
  }

  figure {
    text-align: center;
    display: block;
    padding: 10px;
    background: white;

    img {
      max-width: 100%;
      margin: 0 auto;
    }
  }

  .close-corona {
    position: fixed;
    right: 0;
    top: -1%;
    background: white;
    font-size: 1.8rem;
    line-height: 0;
    padding: 15px 15px;
    color: $azul_prev;
  }
}

.back-banner-corona {
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(black, .8);
  z-index: 999;
  width: 100%;
  height: 100%;
}

body {
  overflow-x: hidden;

  select, input[type='number'], input[type='text'], input[type='email'], textarea {
    font-family: $FordAntenaLight;
  }

  .subtitle {
    text-transform: uppercase;
    color: $azul-gusp;
    //font-weight: 600;
    font-family: $FordAntenaMedium;
  }

  p {
    font-family: sans-serif;
    margin-left: 0;
    padding-left: 0;
  }

  .title-section-web {
    text-transform: uppercase;
    color: $azul-gusp;
    margin: 3px 0;
  }

  .title-page-guspamar {
    color: $azul-gusp;
    font-family: $FordAntenaLight;
    margin: 19px 0;
    font-size: 3.3rem;
    font-weight: 400;
    text-align: center;
    text-transform: uppercase;
  }

  .form-control {
    margin: 0.4em 0;
  }

  .modal-dialog {
    @media (min-width: 980px) {
      width: 90%;
    }
  }

  .btn {
    margin: 0.3em 0;
  }

  input,
  textarea,
  text {
    border-radius: 0 !important;
  }

  &.sticky {
    padding-top: 62px;
  }

  h1, h2, h3, h4, h5 {
    font-family: $Roboto;
    margin: 0;
    padding: 0;
  }

  a {
    font-family: $Roboto;

    &:visited, &:focus {
      text-decoration: none;
    }
  }

  ul {
    padding: 0;
    margin: 0;

    li {
      list-style: none;
    }
  }

}

.logo-web {
  //display: flex;
  //align-items: center;
  //align-content: center;
  //height: 42px;
  //flex-wrap: nowrap;
  //flex-direction: row !important;
  height: 50px;

  position: relative;

  .logo-40 {
    position: absolute;
    left: 0;
    transition: all .4s ease-in-out;
    top: 50%;
    transform: translateY(-52%);
    max-height: 85px;
    //border:1px solid red;
  }

  &.auto-h {
    height: 150px !important;
    width: auto;
    float: right;
    text-align: right !important;

    span {
      //border: 1px solid green;
      width: auto;

      svg {
        width: auto;
        height: auto;

        g, path {
          fill: white;
        }
      }
    }
  }

  > span {

    &.logo-f svg {
      max-height: 35px;
    }

    &.logo-g svg {
      max-height: 35px;
    }

    svg {
      width: 100%;
      height: 100%;
      display: block;
      padding: 0 10px;

      g, path {
        fill: $azul;
      }
    }
  }

}

.button-container {
  position: absolute;
  right: 0;
  height: auto;
  top: 50%;
  transform: translateY(-50%);
  z-index: 888 !important;

  @media (max-width: $break-mobile) {
    right: 0;
    display: none;
  }

  .prev, .next {
    padding: 15px 20px;
    right: 0;
    display: block;
    border: none;
    background: rgba(grey, .3);
    font-size: 2.2rem;
    color: white;
    transition: all .3s ease;

    @media (max-width: $break-mobile) {
      padding: 10px;
    }

    &:hover {
      background: rgba($azul, .5);
    }
  }
}

.slick-dotted.slick-slider {
  margin-bottom: 0 !important;
  height: auto;
}

.slick-dots {
  bottom: 3% !important;

  button:before {
    color: white !important;
    font-size: 9px !important;
    opacity: 1 !important;
  }

  li.slick-active {

    button:before {
      transition: all .3s ease;
      color: transparent !important;
      font-size: 12px !important;
      background: transparent;
      border-radius: 50%;
      border: 2px solid white;
      box-shadow: none !important;
      text-shadow: none;
    }
  }
}


.boton-guspamar {
  background: $azul-gusp !important;
  border: 1px solid $azul-gusp;
  text-transform: uppercase;
  transition: all .3s;

  &:hover {
    background: transparent;
    border: 1px solid $azul-gusp;
    color: $azul-gusp;
  }

  &.outline {
    border: 1px solid $azul-gusp;
    background: transparent !important;
    color: $azul-gusp;
    font-family: $Lato;
  }
}

.old {
  text-decoration: line-through;
  opacity: 0.7;
}

.section-page {
  padding: 40px 0;

  .title-sec {
    margin-top: 0;
  }
}

.form-control-gusp {
  border: none;
  border-bottom: 1px solid rgba($azul-gusp, .5);
  box-shadow: none;
  transition: all .2s;
  width: 100%;
  position: relative;
  text-indent: 5px;

  &::placeholder {
    color: rgba(black, .7);
  }

  &:focus {
    box-shadow: none;
    border-bottom-width: 1px;
    border-bottom-color: rgba($azul-gusp, 1);
  }
}

.form {
  .form-control {
    border: none;
    border-bottom: 1px solid rgba($azul-gusp, .5);
    box-shadow: none;
    transition: all .2s;
    position: relative;

    //&:after{
    //  content: '';
    //  position: absolute;
    //  left: 0;
    //  bottom:0;
    //  width: 100%;
    //  height: 1px;
    //  display: block;
    //  background: $azul-gusp;
    //}

    &::placeholder {
      color: rgba(black, .3)
    }

    &:focus {
      box-shadow: none;
      border-bottom-width: 1px;
      border-bottom-color: rgba($azul-gusp, 1);
    }
  }
}

/* Ensure elements are hidden while ScrollEntrance is loading */
[data-entrance] {
  visibility: hidden;
}

.card {
  box-shadow: 0 0 2px #cacaca;
  padding: 3em;
  clear: both;
  background: white;

  &:after, &:before {
    content: '';
    //display: table;
    line-height: 0;
    display: block;
    clear: both;
  }

  h1, h2 {
    margin: 5px;
  }
}


.btn-primary {
  background: $azul-gusp;
  border: none;
  letter-spacing: 1px;
}

.boton-2 {
  background: white;
  color: $dark-grey;
  border: 1px solid white;
  border-radius: 25px;
  transition: all .3s ease;

  &:hover {
    background: transparent;
    color: white;
    border-color: white;
  }
}

.boton {
  font-weight: 300;
  background: $azul-gusp;
  border: none;
}

#result {
  margin: 0;

  &.alert {
    margin: 0;
  }
}

th.ui-datepicker-week-end,
td.ui-datepicker-week-end {
  display: none;
}

a[data-title],
button[data-title] {
  @media (min-width: 800px) {
    position: relative;

    &:hover:after {
      content: attr(data-title);
      padding: 4px 8px;
      color: #333333;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 110%;
      z-index: 20;
      white-space: nowrap;
      -moz-border-radius: 3px;
      -webkit-border-radius: 3px;
      border-radius: 3px;
      background: white;
      -moz-box-shadow: 0 0 2px #222222;
      -webkit-box-shadow: 0 0 2px #222222;
      box-shadow: 0 0 2px #222222;
    }
  }
}

.comentario {
  white-space: break-spaces;
  font-weight: 400;
  padding: 0;
  line-height: 1.2;
  font-size: 1.4rem !important;
  font-family: Arial, sans-serif !important;
}

.default-text {
  p, samp {
    font-family: Arial, sans-serif !important;
    font-weight: 400 !important;
    padding: 0;
    line-height: 1.2 !important;
    font-size: 1.4rem !important;
    margin-bottom: 15px;
  }
}


.botones {
  margin-top: 5px;
  margin-bottom: 10px;
  display: block;

  > div {
    padding-right: 5px;
    padding-left: 0;
  }
}

.what-link-footer {
  position: fixed;
  right: 5%;
  bottom: 2%;

  @include mobile {
    left: 4.2%;
    bottom: 3.1%;
  }

  a {
    border-radius: 50px;
    height: 60px;
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: green;
    z-index: 888;
    font-size: 3.5rem;
    color: white;
  }
}

.mt-1 {
  margin-top: 10px;
}

.boton-outline {
  border: 1px solid $azul-ford;
  background-color: white !important;
  color: $azul-ford;
  transition: all .3s ease-in-out;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 0;

  &:hover {
    background-color: $azul-ford !important;
    color: white;
  }

}


.preguntas-seg {
  form {
    margin-bottom: 10px;
  }

  h2 {
    font-family: FordAntenna-Medium, sans-serif;
    margin-bottom: 10px;
  }

  .item-pregunta {
    border: 1px solid lightgrey;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;

    h3 {
      color: $azul-gusp;
      margin-bottom: 3px;
      font-family: FordAntenna-Medium, sans-serif;
    }

    .data-res {
      padding-left: 26px;

      p {
        margin: 5px 0;
        padding: 0;
      }
    }
  }
}