section[data-section='f150'] {

  .slide-top {
    position: relative;

    .text {
      position: absolute;
      left: 4.5%;
      top: 10%;
      height: auto;

      h4, h1, h2, h3 {
        color: white;
        text-transform: uppercase;
        font-family: $FordAntenaSemiBold;
        display: block;
        clear: both;
        font-weight: 700;
      }

      h4 {
        font-size: 27px;

        @include mobile {
          margin-bottom: 2px;
          font-size: 1rem;
        }
      }

      h2 {
        transform: translateX(-5px);
        font-size: 87px;
        line-height: 0.9;

        @include mobile {
          transform: translateX(0);
          font-size: 2.8rem;
        }
      }

    }

    .boton-consulta {
      position: absolute;
      left: 4.8%;
      top: 32%;
      background: transparent;
      border: 1px solid white;
      color: white;
      text-transform: uppercase;
      font-family: FordAntenna-Medium, sans-serif;
      padding: 10px 35px;
      font-size: 1.4rem;
      transition: all .3s ease;
      border-radius: 0;

      @include mobile {
        top: unset;
        bottom: 5%;
      }

      &:hover {
        background: white;
        color: $azul-ford-2;
      }
    }
  }

  .video {
    padding: 0;
    display: flex;
    justify-items: center;
    @include mobile {
      iframe {
        height: 250px !important;
      }
    }

    video {
      line-height: 0;
      width: 100%;
    }
  }

  .item {
    display: flex;
    align-items: center;
    box-shadow: none !important;

    &.center-text {
      text-align: center;
      justify-content: center;
      justify-items: center;
      padding: 50px 0;

      h2 {
        display: block;
        text-align: center;
        font-family: $FordAntenaLight;
        color: #75787b;
        font-weight: 200;
        font-size: 36px;
        line-height: 40px;
      }
    }

    @include mobile {
      flex-direction: column;
    }

    &:hover {
      box-shadow: none;
    }

    > div {
      flex: 1 1;
    }

    h3 {
      color: $azul-ford-2;
      letter-spacing: 1px;
      text-transform: uppercase;
      font-size: 3rem;
      border-bottom: 2px solid $azul-ford;
      margin-bottom: 10px;
      padding-bottom: 15px;
      font-family: $FordAntenaMedium;
      font-weight: 900 !important;
      position: relative;

      @include mobile {
        text-align: center;
        font-size: 3rem;
      }

      span {
        color: $azul-ford;
      }

    }

    img {
      width: 100%;
    }

    .data-text {
      left: 0;
      top: 0;
      //border: 1px solid red;
      padding: 0 70px;

      @include mobile {
        padding: 20px 30px;
      }

      &.left {
        //padding-right: 5%;
        h3 {
          text-align: right;
        }

        p {
          text-align: right;
        }

        @include mobile {
          h3, p {
            text-align: center;
          }
        }
      }
    }

    p {
      font-family: "FordAntenna-Light", serif;
      font-weight: 300;
      color: #616161;
      font-size: 1.6rem;
      margin-bottom: 12px;

      @include mobile {
        text-align: center;
      }

      &.especial {
        padding-left: 15px;
        padding-bottom: 15px;
        border-left: 2px solid $azul-ford;
        border-bottom: 2px solid $azul-ford;
      }
    }
  }
}