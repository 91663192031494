section[data-section='cyber-monday'] {

}

.tasas {
  display: flex;
  @include mobile{
    flex-direction: column;
  }
  .item-financiacion {
    border: 1px solid rgba($azul-gusp, .5);
    flex: 1 1;
    margin: 10px 5px;
    transition: all .3s;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      box-shadow: 0 0 4px 1px grey;
    }
    
    label{
      margin-bottom: 0;
      font-size: 1.1rem;
      text-align: center;
      display: block;
    }
    
    header {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 15px 0;
      flex-direction: column;

      h3 {
        text-transform: uppercase;
        margin: 0;
        color: white;
        font-weight: 700;
      }

      span {
        color: white;
      }
    }

    .item-body {
      padding: 20px 10px;
    }
    .boton-fin {
      padding: 10px 10px 5px 10px;
    }
    .item-row {
      display: flex;
      border-bottom: 1px solid rgba(black, .1);
      > span {
        flex: 1 1;
        text-align: center;
        padding: 10px 0;
        font-size: 1.3rem;

        &.title {
          text-transform: uppercase;
          font-weight: 700;
        }
        &.text {
          input {
            text-align: center !important;
          }
        }
      }
    }
  }
}
