.inicio-page {
  @include mobile {
    .slick-dots {
      display: none !important;
    }
  }

  .banner-ranger {
    display: block;
    position: relative;

    .boton-cyber {
      position: absolute;
      right: 1.5%;
      bottom: 3%;

      &:hover {
        border-color: white;
        color: white !important;
      }
    }

    .botones {
      position: absolute;
      bottom: 5%;
      right: 5%;
      @include mobile {
        position: relative;
        display: block;
        left: 0;
        margin: 0;
        .btn {
          margin: 0;
          flex: 1 1;
          width: 100%;
          border: none;
        }
      }

      .btn {
        background: $azul;
        border: 1px solid white;
        color: white;
      }
    }

    .btn-elegir {
      //right: 23%;
      @include mobile {
        //left: 0;
        //width: 50%;
      }
    }

    .btn-vs {
      //right: 5%;
      @include mobile {
        //right: 0;
        //width: 50%;
      }
    }
  }


  .banner-top {
    position: relative;

    .buttons {
      position: absolute;
      right: 10%;
      top: 50%;
      transform: translateY(-50%);

      .btn {
        text-transform: uppercase;
        background: white;
        font-weight: 700;
        color: $azul-gusp;
        transition: all .3s ease;

        &:hover {
          background: $azul-gusp;
          color: white;
        }
      }
    }
  }

  .carousel-inner.slider {
    overflow: hidden;
    max-height: 512px;
  }

  //Suscribirse
  section.suscribe {
    background: $dark-grey;
    padding: 20px 0;

    .title-suc {
      display: flex;
      align-items: center;
      align-content: center;
      height: 45px;

      h3 {
        margin: 0;
        color: white;
        @include mobile {
          font-size: 1.9rem;
        }
      }
    }

    form {
      .btn {
        width: 100%;
      }

      .form-control {
        &:focus {
          box-shadow: none;
          outline: none;
        }
      }
    }
  }

  //Slider Autos Home
  section.slide-autos {

    .titles {
      text-align: center;
      padding: 50px;

      h2 {
        color: $azul;
        font-weight: 300;
        font-size: 4rem;
      }

      h4 {
        font-weight: 300;
        margin-top: 5px;
        color: $dark-grey;
      }

      div {
        padding: 0;
      }
    }

    .sliders-home {
      width: 100%;
      padding: 0 0 40px 0;

      .slick-dots {
        bottom: -19% !important;

        button:before {
          color: $azul !important;
          font-size: 9px !important;
          opacity: 1 !important;
        }

        li.slick-active {

          button:before {
            transition: all .3s ease;
            color: transparent !important;
            font-size: 12px !important;
            background: transparent;
            border-radius: 50%;
            border: 2px solid $azul;
            box-shadow: none !important;
            text-shadow: none;
          }
        }
      }

      .sliders-home-container {
        position: relative;
      }

      ul.slide-selector {
        display: flex;
        width: 40%;
        margin: 0 auto 20px auto;

        @include mobile {
          width: 100%;
          flex-wrap: wrap;
        }

        li {
          flex: 1 1;
          text-align: center;
          text-transform: uppercase;
          padding: 10px;

          @include mobile {
            flex: 0;
            flex-basis: 50% !important;
            padding: 5px;
            font-size: 1.2rem;
          }
        }
      }

      .item-slide {
        text-align: center;

        h1 {
          margin: 15px 0 0 0;
          text-transform: uppercase;
          font-size: 2rem;
        }

        img {
          display: block;
          margin: 0 auto;
          height: 150px;
          width: auto;
        }
      }
    }
  }

  //Nuevos KA
  section.novedad {
    position: relative;
    height: auto;
    width: 100%;
    margin-top: 20px;
    display: flex;

    @include mobile {
      flex-direction: column;
    }

    > div {
      flex: 1;
      position: relative;

      .btn {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 5%;
        padding: 10px 25px;
        background: $azul;
        color: white;
        border-width: 2px;
        font-size: 1.7rem;
        letter-spacing: 1px;

        @include mobile {
          padding: 8px 25px;
          font-size: 1.2rem;
        }
      }
    }

    .titles {
      text-align: center;
      width: 100%;
      position: absolute;
      top: 5%;

      h2 {
        font-size: 5.5rem;
        font-weight: 600;
        @include mobile {
          font-size: 2.8rem;
        }
      }

      h4 {
        font-size: 2rem;

        @include mobile {
          font-size: 1.8rem;
        }
      }
    }
  }

  //Bloques inicio
  section.bloques {

    > h3 {
      text-align: center;
      display: block;
      color: $azul;
      padding: 45px 0;
      font-weight: 300;
      font-size: 4rem;
    }

    div {
      padding: 0;
    }

    .block {
      position: relative;
      min-height: 300px;
      padding: 0;
      overflow: hidden;

      a {
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        position: absolute;
        width: 100%;
        height: 100%;
        display: block;
        bottom: 0;
        left: 0;
        transition: all .3s ease;

        &:hover {
          transform: scale(1.04);
        }
      }

      span.title-link {
        background: rgba($negro_black, .7);
        padding: 10px 0 10px 15px;
        width: 100%;
        color: white;
        bottom: 0;
        left: 0;
        font-size: 2.3rem;
        position: absolute;

        @include mobile {
          font-size: 1.9rem;
        }
      }

      &.block-1 {
        height: 600px;

        @include mobile {
          height: auto;
        }

        a {
          background-image: url("/www/img/home/block_01.jpg");
          @include mobile {
            background-image: unset;
          }
        }
      }

      &.block-2 {
        a {
          background-image: url("/www/img/home/block_02.jpg");
        }
      }

      &.block-3 {
        a {
          background-image: url("/www/img/home/block_03.jpg");
        }
      }

      &.block-4 {
        a {
          background-image: url("/www/img/home/block_04.jpg");
        }
      }

      &.block-5 {
        a {
          background-image: url("/www/img/home/block_05.jpg");
        }
      }

    }
  }

  //Instagram
  section.instagram {
    background: $dark-grey;
    padding-bottom: 10px;
    padding-top: 10px;

    .title-inst {
      padding: 30px 0;

      figure {
        line-height: 0;

        svg {
          display: block;
          margin: 0 auto;
          height: 50px;

          g, path {
            fill: white;
          }
        }
      }
    }

    .item-instagram {
      height: 200px;
      width: 200px;

      a {
        width: 100%;
        height: 100%;
        background-position: center center;
        background-size: cover;
        display: block;
      }
    }

    .boton-2 {
      margin: 18px auto 0 auto;
      display: block;
      width: 12%;
      text-transform: uppercase;

      @include mobile {
        width: 70%;
      }
    }
  }

  section.videos {
    background: black;
    padding: 15px 0 35px 0;

    h3, h2 {
      text-transform: uppercase;
      color: white;
      font-weight: 300;
      font-size: 4rem;
      padding: 10px 0 15px 0;
    }

    > div {
      text-align: center;
    }

    //.slider-videos {
    //  display: block;
    //  margin: 0 auto;
    //  width: 800px;
    //  border:1px solid red;
    //  height: 500px;
    //}

  }

  section.bloques-home {
    .container-fluid {
      padding-bottom: 55px;
      @include mobile {
        .col-xs-12 {
          margin-bottom: 10px;
        }
      }
    }

    .titles {
      display: block;
      //border: 1px solid red;
      padding-top: 55px;
      padding-bottom: 45px;

      h1, h3 {
        text-align: center;
        display: block;
        font-family: $FordAntenaRegular;
        letter-spacing: 1px;
      }

      h3 {
        padding-top: 10px;
        font-family: $FordAntenaExtraLight;
        color: $azul-ford;
      }
    }
  }
}

#modelId {

  .close {
    position: absolute;
    right: 1%;
    top: 2.7%;
    color: black;
  }

  .modal-title {
    text-transform: uppercase;
    font-family: $FordAntenaSemiBold;
  }
}

.slide-videos {

  > div {
    a.item-v {
      position: relative;

      h4 {
        font-size: 1.3rem;
        width: 100%;
        position: absolute;
        font-family: $FordAntenaSemiBold;
        color: white;
        text-transform: uppercase;
        top: 15px
      }

      i {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        color: white;
        text-shadow: 0 0 3px black;
        font-size: 4.5rem;
      }

      img {
        display: block;
        margin: 0 auto;
        padding: 5px;
      }
    }
  }

}

a.suscribite {
  color: white;
  background: transparent;
  border: 1px solid white;
  border-radius: 20px;
  padding-left: 28px;
  padding-right: 28px;
  font-family: $FordAntenaSemiBold;
  transition: all .3s ease;
  margin-top: 10px;

  &:hover {
    color: black;
    background: white;
  }
}

.evento {
  position: relative;

  .contador {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    font-family: "FordAntenna-Black", sans-serif;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .data {
      width: 75%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      text-align: center;

      span {
        font-size: 30px;
        padding: 0 10px;
        color: white;
        @include mobile{
          font-size: 4vw;
        }
      }

      .count {
        font-size: 95px;
        display: block;
        color: #11ddff;
        line-height: 1.2;
        @include mobile{
          font-size: 9vw;
        }
      }
    }

    h2, p, h3 {
      color: white;
      font-family: "FordAntenna-Black", sans-serif;
      text-align: center;
    }

    h2 {
      font-size: 5vw;
    }

    p {
      padding-top: 15px;
      font-size: 3vw;
      @include mobile{
        padding-top: 2px;
      }
    }
  }
}