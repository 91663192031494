section[data-section='grandes-clientes'] {

  .tabs-grandes {
    padding: 0 10%;
    display: block;
    margin: 0 auto;
  }

  .portada {
    img {
      display: block;
      margin: 0 auto;
    }
  }

  .center-grandes-clientes {

    .title-first {
      text-align: center;
      font-family: $FordAntenaSemiBold;
      padding: 15px 0 25px 0;
    }

    > p {
      //border:1px solid red;
      padding-top: 25px;
      text-align: center;
      font-size: 1.8rem;
      color: grey;
      font-family: $FordAntenaRegular;
      font-weight: 500;
      padding-bottom: 10px;
      display: block;
    }

    h3 {
      font-size: 2.9rem;
      letter-spacing: 1px;
      color: $azul;
      font-weight: 800;
      margin-bottom: 5px;
      @include mobile {
        padding: 0 20px;
        width: 90%;
        margin: 0 auto;
        font-size: 1.5rem;
      }
    }

  }

  .beneficios {
    display: flex;
    padding: 30px 0;
    background: rgba(238, 238, 238, 0.7);
    flex-direction: column;

    h3 {
      font-family: $FordAntenaSemiBold;
      display: block;
      text-align: center;
      padding-bottom: 15px;
    }

    ul {
      display: block;
      margin: 0 auto;
      width: auto;

      li {
        font-family: $FordAntenaBlack;
        font-size: 2.5rem;
        @include mobile {
          font-size: 4.2vw;
        }

        img {
          padding-right: 5px;
        }
      }
    }
  }

  .beneficiarios {
    height: 350px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @include mobile {
      display: flex;
      flex-direction: column;
      width: 100%;
      text-align: center;
      height: auto;
      padding: 20px 0;
    }

    h3 {
      font-family: $FordAntenaSemiBold;
      margin-bottom: 15px;
    }

    ul {
      display: flex;
      @include mobile {
        flex-direction: column;
      }

      li {
        padding: 0 20px;

        @include mobile {
          padding: 0 20px;
          margin-bottom: 20px;
        }

        a {
          box-shadow: 0 0 21px #dddddd;
          border-radius: 50%;
          height: 150px !important;
          width: 150px;
          display: block;
          transition: all .3s ease;

          img {
            transition: all .3s ease;
          }

          h4 {
            display: block;
            text-align: center;
            margin-top: 10px;
            opacity: 0;
            transition: all .3s ease;
            text-transform: uppercase;
            font-size: 14px;
            font-weight: 800;
            font-family: $FordAntenaSemiBold;
            transform: translateY(8px);
          }

          &:hover {
            box-shadow: 0 0 21px #cbcbcb;

            img {
              transform: scale(1.1);
            }

            h4 {
              opacity: 1;
            }
          }
        }
      }

      .modal {

        .modal-dialog {
          max-width: 70%;
          width: 50%;
          //top: 50%;
          //transform: translate(0, -50%);

          @include mobile {
            width: unset;
            max-width: unset;
            //width: 90%;
            //left:50%;
            //top:50%;
            top:unset;
            transform: unset !important;
            max-height: 800px;
            //transform: translate(-50%, -50%);
          }
        }

        .modal-header {
          border: none;
        }

        ul {
          display: unset;
          padding-left: 0;
          margin: 0;

          li {
            padding-left: 0;
            margin: 0;
            padding-bottom: 5px;
            font-weight: 500;
          }
        }
      }
    }
  }

  .vehiculos {
    text-align: center;
    padding-bottom: 30px;

    h3 {
      font-family: $FordAntenaSemiBold;
    }
  }

  .empresas {
    h3 {
      font-family: $FordAntenaSemiBold;
      text-align: center;
      display: block;
      padding-bottom: 15px;
    }

    .slide-empresas {
      width: 60%;

      @include mobile {
        width: 80%;
      }

      margin: 0 auto;
      display: block;

      .slick-slide {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 180px;
        //border: 1px solid grey;

        img {
          width: 60%;
        }
      }
    }

  }

  .contacto {
    text-align: left;
    padding-top: 40px;

    h3 {
      display: block !important;
      text-align: center;
      width: 100%;
      clear: bottom;
      font-family: $FordAntenaSemiBold;
    }

    h5 {
      margin-bottom: 10px;
      text-align: center;
    }

    form {
      h3 {
        display: block;
        clear: left;
        padding-top: 20px;
        padding-bottom: 5px;
        font-weight: 400;
        font-size: 1.6rem;
      }
    }
  }

  .empresas {
  }

  article {
    display: block;
    padding: 15px 0;
  }

  #myTabs {
    padding-top: 20px;
    padding-bottom: 10px;

    li {
      border-radius: 0;

      a {
        border-radius: 0;
        text-transform: uppercase;
        font-weight: 800;
        font-size: 1.2rem;
        font-family: $FordAntenaBlack;
      }
    }
  }

  .title-tab {
    font-size: 2.5rem;
    color: $azul;
    font-family: $FordAntenaSemiBold;
    text-transform: uppercase;
    margin-bottom: 15px;
    font-weight: 700;
    letter-spacing: 1px;

    &.center {
      //border:1px solid red;
      padding: 10px 0 20px 0;
      margin: 0 auto;
      text-align: center;
    }
  }

  .tab-pane {

    img {
      margin-bottom: 20px;
      display: block;
    }

    h4 {
      text-transform: uppercase;
      font-weight: 600;
      font-family: $FordAntenaBlack;
      color: $azul;
      margin-top: 15px;
      margin-bottom: 2px;
    }
  }

  .form-grandes {
    .item-checks {
      display: block;
      //border: 1px solid green;
      padding-bottom: 20px;

      h4 {
        font-weight: 400;
        color: $azul-gusp;
        font-family: $FordAntenaSemiBold;
      }

      .checkboxs {
        display: flex;

        > div {
          //border: 1px solid red;
          padding: 5px 10px 0 0;

          input[type='radio'] {
            transform: translateY(1px);
          }

          span {
            font-weight: 700;
          }
        }
      }

      .politicas {

        input[type='radio'] {
          transform: translateY(1px);
        }

        h6 {
          font-family: $FordAntenaSemiBold;
          font-weight: 800;
        }
      }
    }
  }
}