.encuestas-section {
  .navbar-default {
    padding: 15px 0;
    text-align: center;

    img {
      display: block;
      margin: 0 auto;
    }
  }
}