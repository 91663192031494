.centro {
  background: white;
  height: auto;
  //box-shadow: 0 0 2px black;
  padding-bottom: 1.5em;

  h1, h2 {
    margin-top: 1em;
  }

  img {
    //margin-bottom: 1em;
  }

  h3 {
    text-transform: uppercase;
    margin-top: 0.2em;
  }
}