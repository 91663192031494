section[data-section="ranger-campaign"] {

  .boton-inicia {

    font-size: 11px;
    text-shadow: none;
    border-radius: 0;
    color: black;
    text-align: right;
    font-family: $FordAntenaBlack;
    text-transform: uppercase;
    background: transparent;
    border: 1px solid black;

    @include mobile {
      bottom: 5%;
      width: 60%;
      transform: translateX(-50%);
      left: 50%;
      right: unset;
    }

    &:hover {
      box-shadow: 0 0 2px white;
      background-color: $azul;
      color: white;
      border-color: white;
    }

    span {
      float: left;
    }

    img {
      height: 30px;
      padding-left: 5px;
      float: right;
    }

  }


  .item-ranger {
    position: relative;
    padding-bottom: 40px;

    .boton-inicia {
      position: absolute;
      right: 10%;
      bottom: 17%;
    }

    .btn-2 {
      right: 23%;
    }
  }


  .grandes {
    background-size: contain;
    height: 1668px;
    overflow: hidden;

    @include mobile {
      height: auto;
      background-size: cover;
    }

    > .container {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
      height: 70%;

      @include mobile {
        height: 100%;
        padding: 40px 10%;
      }

      .logo-grandes {
        margin-bottom: 40px;
      }

      .boton-inicia {
        border-color: white;
        color: white;
        padding: 10px 15px;
        transform: unset;
        font-size: 12px;
        letter-spacing: 1px;
        position: relative;

        @include mobile {
          margin-top: 20px;
          background: rgba(white, .6);
          color: black;
          width: 90%;
          position: unset;

        }
      }
    }
  }

  .post-venta {
    .logo-postventa {
      @include mobile {
        margin-bottom: 20px;
      }
    }

    .lateral-img {
      @include mobile {
        margin-top: 20px;
        margin-bottom: 10px;
      }
    }

    > .container-fluid, > .container {
      padding-top: 40px;
      padding-bottom: 40px;

      .agenda-boton {
        background: transparent;
        text-shadow: none;
        text-transform: uppercase;
        color: white;
        padding: 10px 25px;
        font-family: $FordAntenaLight;
        letter-spacing: 1px;

        &:hover{
          background-color:white;
          color:black;
        }
      }

      .top-post-venta {
        padding-bottom: 30px;
        //border: 1px solid red;
        display: block;
      }

    }

  }
}