section[type='modelos'] {
  h2.title {
    clear: both;
    display: block;
    text-transform: uppercase;
    padding-top: 10px;
    padding-bottom: 10px;
    font-family: $FordAntenaMedium;
    color: $azul-gusp;
    text-align: center;
  }

  ul.autos-list {
    //display: flex;
    //flex-wrap: wrap;

    li.item-auto {
      text-align: center;
      //flex-basis: 50%;
      height: 230px;
      //border:1px solid red;

      img {
        max-height: 180px;
        display: block;
        margin: 0 auto;
      }

      a {
        color: black;
      }

      h3 {
        font-family: $FordAntenaMedium;
        font-size: 2rem;
      }
    }
  }
}