section[data-section="ford-pass"] {
  .buttons {
    width: 100%;
    //border:1px solid red;
    display: flex;
    justify-content: center;

    button {
      background: $azul-ford-2;
      color: white;
      padding: 10px 15px;
      margin: 5px;
      transition: all .2s ease-in;
      border: 1px solid $azul-ford-2;

      &:hover {
        background: whitesmoke;
        color: $azul-ford-2;
      }

    }
  }

  h2, h3 {
    font-family: "FordAntenna-Medium", Arial, sans-serif;
    color: $azul-ford-2;
  }

  .boton {
    background: transparent;
    border-radius: 20px;
    padding-left: 20px;
    padding-right: 20px;
    font-family: "FordAntenna-Medium", Arial, sans-serif;
    border: 1px solid $azul-ford-2;
    color: $azul-ford-2 !important;
    text-shadow: none;
    transition: all .2s ease-in;

    &:hover {
      background: $azul-ford-2;
      color: white !important;
    }

    &.white {
      color: white !important;
      border-color: white;


    }
  }

  p {
    font-family: "FordAntenna-Light", Arial, sans-serif;
    color: $azul-ford-2;
    font-size: 20px;
  }

  .banner {
    position: relative;
    margin-bottom: 30px;

    figcaption {
      position: absolute;
      left: 6.6%;
      top: 69.3%;
    }
  }

  .que-es {
    padding-top: 50px;
    padding-bottom: 50px;
    text-align: center;

    h2 {
      margin-bottom: 30px;
    }

    p {
      text-align: center;
    }
  }

  .hacer {
    padding-top: 50px;
    padding-bottom: 50px;

  }

  .asistencia {
    background: #eaeaea;

    h3 {
      padding-bottom: 10px;
    }

    p {
      font-size: 15px;
    }

    .container {
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      @include mobile {
        flex-direction: column;
      }

      .data {
        padding-left: 20px;
      }
    }
  }

  .guia-360 {
    position: relative;

    figcaption {
      position: absolute;
      right: 14.8%;
      top: 52%;
    }
  }


  .conectarse {
    padding: 30px 0;

    h2 {
      padding-bottom: 40px;
    }
  }
}