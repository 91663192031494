#contratar-sec {
  //Header
  .header {
    margin: 2rem 0 1rem 0;
    span {
      width: 100%;
      //border: 1px solid red;
      display: block;

      h3 {
        color: white;
        font-size: 2rem;
        line-height: 20px;
        margin-bottom: 11px;
        margin-top: 11px;
      }
    }

    .active, .inactive {
      text-align: center;
    }

    .active {
      background-image: url("../img/active.svg");
      background-position: right center;
      background-repeat: no-repeat;
      background-size: contain;
      background-color: white;
      //border:1px solid red;
    }

    .inactive {
      background-image: url("../img/inactive.svg");
      background-position: right center;
      background-repeat: no-repeat;
      background-size: contain;
      background-color: white;

      h3 {
        color: black;
      }
    }
  }

  .explicacion {
    .item-exp {
      text-align: center;

      h4 {
        font-size: 1.6rem;
      }

      i {
        font-size: 7em;
      }

    }

  }

  //Paso 1
  .contratado{
    position: relative;

    h2,h4{
      right: 0;
      position: absolute;
      background: rgba($azul,.9);
      text-shadow: 0 0 2px black;
      padding: 3px 8px;
      color:white;
    }

    h2{
      margin-top: 0;
      font-size: 18px;
      top: 5%;
    }
    h4{
      margin-top: 0;
      font-size: 28px;
      top:18%;
    }
  }

  //Paso 2
  .download-form {
    text-align: center;
    color: $azul-gusp;
    i {
      display: block;
      font-size: 20em;
    }
    span {
      margin-top: 10px;
      display: block;
      font-size: 1.8rem;
      text-transform: uppercase;
    }
  }

  //Paso 3
  .paso-3 {

    .card {
      li {
        display: block;
        border-bottom: 1px solid lightgrey;
        padding: .5em 0;
        font-weight: 300;
        font-size: 1.3rem;

        b {
          width: 90px;
          display: inline-block;
          //padding-right: 1em;
        }
      }
    }

    .data {
      background: $azul;
      padding: .8em;
      color: white;
      text-align: center;
      h4 {
        font-weight: 300;
        margin: 0;
        text-transform: uppercase;
      }
      h2 {
        font-size: 4.3rem;
        font-weight: 300;
        margin: 0;
        margin-top: 5px;
      }
    }
  }

  .promos {
    position: fixed;
    left: 50%;
    top: 50%;
    z-index: 999;
    width: 70%;
    height: 90%;
    background: white;
    padding: 2em;
    transform: translate(-50%, -50%);
    box-shadow: 0 0 2px grey;
    overflow: scroll;
    display: none;
  }

  .back-promos {
    position: fixed;
    background: rgba(0, 0, 0, .5);
    left: 0;
    top: 0;
    z-index: 888;
    width: 100vw;
    height: 100vh;
    display: none;
  }

  .item-plan {
    //min-height: 350px;
    background: white;
    box-shadow: 0 0 2px 1px rgba(grey, .3);
    margin-bottom: 10px;
    margin-top: 15px;
    padding: 15px;
    position: relative;
    transition: all .3s;


    &:hover {
      box-shadow: 0 0 22px 2px rgba(grey, .8);
    }

    a {
      color: black;

      &:hover {
        img {
          transform: scale(1.05);
        }
        .btn {
          background: darken($azul-gusp, 10);
        }
      }

      h3 {
        font-size: 22px;
        margin-bottom: 0;
        font-weight: normal;
      }


      .tipo-plan {
        text-transform: uppercase;
        color: $azul-gusp;
      }

      figure {
        line-height: 0;
        height: 180px;
        //border: 1px solid grey;
        overflow: hidden;

        img {
          transition: all .3s;
          width: 100%;
          height: auto;
        }
      }
    }

    .desde {
      color: #292929;
      font-size: 20px;
      font-weight: 500;
    }

    .btn {
      position: relative;
      color: white !important;
      transition: all .3s;
      margin-top: 10px;
    }

  }
}